html, #body, #root{
    height: max-content;
}
#root{
    width: 100%;
}
a {
    color:#00A94E;
    text-decoration: none;
}
a:visited {
    color:#00A94E;
    background-color: transparent;
    text-decoration: none;
  }
  a:hover {
    color:#00A94E;
    background-color: transparent;
    cursor: pointer;
  }
  a:active {
    color:#00A94E;
    background-color: transparent;
  }

#root  .main-menu-wrapper {
    width: calc(50vw + 75px);
}

#root .primary-bar-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-right: 5%;
}
#root .primary-bar-wrapper .nav--friendly--message{
    padding: 0px 12px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    font-size: 1em;
    text-transform: uppercase;
    color: white;
    opacity: 1;
    align-self: center;
    width: max-content;
    padding-top: 10px;
}

.sign-in-container {
    display: none;
}

.sign-in-container a {
    color: #fff;
}
.sign-in-container a:hover {
    color: #00A94E;
}

.container {
    width: 48vw;
}
#mainContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
div, a, span, label {
    font-family: 'Titillium Web', sans-serif;;
}

button, a, h1, h2, h3 {
    font-family: 'Titillium Web', sans-serif;;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/titilliumweb/v9/NaPecZTIAOhVxoMyOr9n_E7fdMPmDaZRbrw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

.just-jacket-selected .pant-trim,
.just-pants-selected .coat-trim {
    display: none !important;
}

#root .player-container:not(.summary) {
    min-height: calc(100vh - 240px);
    grid-gap: 0px;
    margin: 0;
    display: flex;
    width: 100vw;
}
.col-padding{
    padding-left: 9%;
  }
.template-title {
    color: #000000;
    text-transform: uppercase;
    font-size: 28px !important;
    margin-bottom: 10px !important;
    display: none;
}
.disclaimer{
    display: none;
}
.disclaimer  a {
    text-decoration-line: underline;
    text-decoration-style: dotted;
}

#root .player-container.summary .disclaimer {
    /* display: block; */
    text-align: center;
    height: max-content !important;
    font-size: 13px;
    align-self: center;
}
.template-subtitle {
    color: #000000;
    font-size: 20px !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    margin-top: 10px !important;
}
#root .player-container.summary .zoom-wrapper {
    height: 0;
    max-width: 0; 
    position: 'absolute'
}
#root .player-container.summary .container{
    float:right;
    width:45vw !important;
    margin-top: 0;
}

.template-subtitle + div {
    border-bottom: none;
}


#player-root {
    margin-top: 0;
    min-height: 97%;
    width: 50vw;
}

/* #root.just-jacket-selected .item-pants,
#root.just-pants-selected .item-jacket  {
    display: none;
} */

#root.just-jacket-selected .item-pants,
#root.just-pants-selected .item-jacket  {
    display: none;
} 

#root .section-wrapper {
    height: auto;
    padding: 15px 0 0;
    
}

#root .template-wrapper > div {
    border: none;
}

#root .section-divider {
    margin-bottom: 20px;
}

#root .template-wrapper .section-wrapper .section-label {
    font-size: 18px;
    color:#00A94E;
}

.iIYyEp {

}
.a-letters-text .input-label, 
.b-letters-text .input-label, 
.c-letters-text .input-label, 
.d-letters-text .input-label, 
.e-letters-text .input-label, 
.f-letters-text .input-label{
    margin-bottom: 2%;
}
.input-component{
    box-sizing: border-box;
    margin-bottom: 5%;
    padding-left: 8px;
}
.moisture-barrier {
    margin-bottom: 10%;
}
.inputs-container:last-child{
    margin-bottom: 5%;
}
.input-component input[type=text] {
    border: 1px solid #00A94E;
    margin-right: 12px;
    margin-bottom: 15px;
    padding: 5px 10px;
    border-radius: 0;
    /* box-shadow: none; */
    color: grey;
    font-size: 16px;
    letter-spacing: 1px;
    max-width: 295px;
}

.input-component div,
.input-component div:hover {
    /* box-shadow: none; */
    transform: none;
}

#root .Omcxy {
    display: grid;
    display: flex;
    grid-template-columns: auto max-content;
    grid-gap: 8px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.input-label {
    height: 18px;
    color: #00A94E;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

.input-description {
}

.input-wrapper .ezCyTr {
    border: 2px solid #00A94E;
}

.input-wrapper div {
    padding: 0;
    margin: 0;
}

.input-wrapper > div > div > div {
    padding: 10px;
    margin: 10px;
}


.section-input-options > div {
    padding: 0 !important;
}

.section-input-options .input-component .input-wrapper .cqMdHh > div{
    padding: 5px 10px;
    border-radius: 0;
    /* box-shadow: none; */
    border: 1px solid #00A94E;
    text-transform: uppercase;
    margin: 10px 20px 10px 0;
    font-size: 16px;
}

.section-input-options .input-component .input-wrapper .kWPUgO {
    /* font-size: 12px; */
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    color: white;
    background: #00A94E;
    -webkit-transform: none;
    transform: none;
}

/* Sumary Section */
#root .summary {
    /* display: block; */
    width: max-content;
    position:relative;
    min-height: calc(100vh - 120px);
    float:right;
}

/* #root .buttonBar{
    display:none;
} */

#root .summary > div {
    /*height: 500px;*/
}

#root .summary > div #player-root {
    height: 100%;
}

#root .summary .regular-wapper, #root .summary-wrapper {
    display: none;
}
#root .summary .summary-wrapper{
    display: block;
    position: relative;
    margin-top: 2rem;
    overflow-y: auto;
    height: calc(100vh - 180px);
}
.dd--span__breadcrum{
    font-size: 22px;
    margin: 0 10px;
}
#root .regular-wapper{
    height:calc(100vh - 240px);
    display: block;
    padding-bottom: 20px;
    /*padding-top: 40px;*/
    /*position: relative;*/
}
#root .summary > div {
    /* margin: auto; */
}

#root .summary .summary-wrapper .template-subtitle {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
}

#root .summary .summary-wrapper .configuration-wrapper{
    padding-bottom: 30px;
}
#root .summary .summary-wrapper .configuration-wrapper > .group.configuration-pants{
    margin-top: 25px;
}
#root .summary .summary-wrapper .configuration-wrapper .group.configuration- .group-header{
    margin-top: 25px;
}
#root .summary .summary-wrapper .configuration-wrapper .group.configuration-{
    margin-bottom: 20px;
}
#root .summary .summary-wrapper .configuration-wrapper,
#root .summary .summary-wrapper .lettering-wrapper,
#root .summary .summary-wrapper .submit-wrapper,
#root .summary .summary-wrapper .save-configuration-wrapper{
    display: none;
}

#root .summary.configuration .summary-wrapper .configuration-wrapper,
#root .summary.lettering .summary-wrapper .lettering-wrapper,
#root .summary.share-via-email .summary-wrapper .submit-wrapper,
#root .summary.save-configuration .summary-wrapper .save-configuration-wrapper{
    display: block;
    padding-bottom: 20px;
}

#root .summary .group-header {
    background: #ddd;
    padding: 10px 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    border-left: 0.5px solid #858990;
    border-right: 0.5px solid #858990;
    border-bottom: 0.5px solid #858990;
    border-top: 0.2px solid #858990;
}

#root .summary .group-content .group-content-row {
    padding-left: 10px;
    display: flex;
    border-bottom: 1px solid #858990;
    flex-wrap: wrap;
    justify-content: space-between;
}

#root .content-email {
    margin-bottom: 50px;
}

#root .summary .group-content .submit-row {
    width: 100%;
    max-width: 500px;
    display: flex;
}

#root .summary .group-content .submit-row input {
    width: 40%;
}

#root .summary .group-content .submit-row label {
    display: inline-block;
    /* min-width: 200px; */
    width: 28%;
    margin: 10px;
}

#root .form-message-error {
    display: inline-block;
    width: 33%;
    margin: 10px;
    color: rgb(243, 14, 14);
}

#root .summary .group-content .submit-row .form-input-label{
    width: 28%;
}

#root .summary .group-content .submit-row .group-content-input .form-input-group{
    width: 25%;
    margin: 10px 10px 0px 10px;
}

#root .form-group-message-error{
    display: block;
    width: 100%;
    padding: 0px 10px;
    color: rgb(243, 14, 14);
}

#root .summary .group-content .submit-row .group-content-input .form-input-group.input-pants{
    margin: 28px 10px 10px 10px;
}

#root .group-content-input{
    width: 60%;
}

#root .summary .group-content .submit-row .form-checkbox-input{
    width: 6%;
    margin: 10px;
    height: 60%;
}

#root .summary .group-content .submit-row .form-checkbox-label{
    width: 50%;
    margin: 6px;
    padding-top: 4px;
}

#root .summary .summary-wrapper .lettering-wrapper > div {
    /* text-align: center; */
    margin-bottom: 10px;
}

#root .summary .summary-wrapper .lettering-wrapper > div label{
    font-size: 14px;
    color: #333;
}


#root .summary .summary-wrapper input {
    padding: 2px 10px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #00A94E;
    text-transform: uppercase;
    margin: 10px;
    font-size: 14px;
}

#root .summary .summary-wrapper input.white-btn {
    padding: 2px 35px;
    border-radius: 0;
    background: white;
    color: #00A94E;
}

#root .summary .summary-wrapper input.green-btn {
    padding: 5px 35px;
    border-radius: 0;
    background: #00A94E;
    color: #ffffff;
}

#root .configuration-table{
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #CCCDCD;
    border-left: 1px solid #CCCDCD;
    border-right: 1px solid #CCCDCD;
}

#root .configuration-table-tr{
    width: 100%;
}

dd{
    display: flex;
    align-items: center;
    font-size: 1em;
    text-transform: capitalize;
}

#root .configuration-table-optionNumber{
    text-transform: capitalize;
    width: 33.3%
} 
#root .configuration-table-attribute{
    align-self: center;
    font-weight: 700;
    font-size: 1em;
    margin-bottom: 0.5em;
    margin-left: 0;
}
#root .configuration-table-optionGroup{
    width: 33.3%;
}
#root .configuration-table-optionDescription{
    width: 33.3%;
    text-transform: capitalize;
}

#root .content-submit{
    text-align: center;
    padding-bottom: 30px;
}

#kev-pouch-2x7x8, #kev-pouch-2x8x9{
    display: none;
}

#root  .form-submit{
    background: #03a059;
    color: white;
    width: 160px;
    font-size: 17px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #007537;
}

.form-submit:hover {
    cursor: pointer;
}


.button-modal {
    background: #03a059;
    color: white;
    width: 100px;
    font-size: 17px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #007537;
}

.button-yes{
    margin-right: 20px;
}
#root .secondary-bar-wrapper .secondary-bar-item.visited {
    background-color: #ffffff;
    color: #808080;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0;
}
#root .player-container.materials .secondary-bar-wrapper .materials-menu-item,
#root .player-container.trim .secondary-bar-wrapper .trim-menu-item,
#root .player-container.stormflap .secondary-bar-wrapper .stormflap-menu-item,
#root .player-container.side-pockets .secondary-bar-wrapper .side-pockets-menu-item,
#root .player-container.collar .secondary-bar-wrapper .collar-menu-item,
#root .player-container.chest .secondary-bar-wrapper .chest-menu-item, 
#root .player-container.front .secondary-bar-wrapper .front-menu-item, 
#root .player-container.sleeves .secondary-bar-wrapper .sleeves-menu-item, 
#root .player-container.back-lettering .secondary-bar-wrapper .back-lettering-menu-item,
#root .player-container.other .secondary-bar-wrapper .other-menu-item,
#root .player-container.configuration .secondary-bar-wrapper .configuration-menu-item,
#root .player-container.lettering .secondary-bar-wrapper .lettering-menu-item,
#root .player-container.share-via-email .secondary-bar-wrapper .email-menu-item
 {
    background-color: gray;
    color: #fff;
  }
.button-modal:hover {
    cursor: pointer;
}

.modal-message {
    margin-bottom: 20px;
}
.three-d-player-space {
    position: relative;
}
.three-d-player-space .zoom-wrapper {
    right: 50px;
    top: 20px;
    height: 20px;
    max-width: 10px;
    float: none;
    position: absolute;
}
.accordionIcon {
    display: none;
}

.action-accordion{
    padding: 0px;
    width: 32%;
    height: 3%;
    position: absolute;
    background: black;
}

.next-btn {
    padding: 10px 10px; 
    /* border-radius: 0;
    box-shadow: none;
    border: 1px solid #007537; */
    text-transform: uppercase;
    font-size: 20px;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s;
    background:white;
    color:#858990;
    transform: translateY(-4px);
    display: inline-block;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    bottom: -8px;
    right: 0;
    width: 100%;
    text-align: right;
    background: white;
}

header{
    background: black;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.header-logo{
    width: 166px;
    margin-left: 5%;
}

#root .secondary-bar-wrapper{
    /*justify-content: inherit;*/
    /* flex-wrap: wrap; */
    /* box-sizing: border-box;
    border-bottom: 1px solid #CCCDCD; */

    /*display: grid;*/
    grid-gap: calc(var(--gutter) / 2);
    grid-template-columns: 10px;
    grid-template-rows: minmax(150px, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: calc(50% - var(--gutter) * 2);
    scroll-snap-type: x proximity;
    padding-bottom: calc(.75 * var(--gutter));
    margin-bottom: calc(-.25 * var(--gutter));
}

#root .jacket .secondary-bar-wrapper{
    display: flex;
    flex-wrap: wrap;
    height: 90px;
    flex-direction: column;
    width: 90vw;
    overflow: hidden;
}



/* #root .jacket .nav--subheaders{
    justify-content: space-between;
} */

#root .jacket .nav--subheaders .rightButton,
#root .jacket .nav--subheaders .leftButton {
    position: absolute;
    top: 15px;
}  

#root .jacket .nav--subheaders .rightButton {
    right: -45px;
}
#root .jacket .nav--subheaders .leftButton {
    left: -45px;
}

#root .secondary-bar-wrapper .secondary-bar-item {
    justify-content: center;
    align-items: center;
    margin: 0;
    /*padding:20px;*/
    text-align: center;
    color: #00A94E;
}
footer{
    text-align: center;
    background-color: black;
    color: white;
    height: max-content;
    padding-top: 14px;
    position: fixed;
    width: 100%;
    bottom: 0;

}

.header-gear{
    color:#00A94E;
    padding-top: 15px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: bold;
}

.gx3__non-visible{
    display: none;
}

.all-suite .gear-gx3 .gear-img,
.just-jacket-selected .gear-gx3-coat .gear-img,
.just-pants-selected .gear-gps-pant .gear-img,
.all-suite .gear-athletix .gear-img,
.just-jacket-selected .gear-jacket .gear-img,
.just-pants-selected .gear-pants .gear-img
{
    border: 2px solid #00A94E;
}    

.all-suite .gear-gx3 .gear-label,
.just-jacket-selected .gear-gx3-coat .gear-label,
.just-pants-selected .gear-gps-pant .gear-label,
.all-suite .gear-athletix .gear-label,
.just-jacket-selected .gear-jacket .gear-label,
.just-pants-selected .gear-pants .gear-label
{
    color: #00A94E !important;
}


.just-jacket-selected .configuration-pants,
.just-pants-selected .configuration-jacket,
.just-jacket-selected .pants-trim-type,
.just-jacket-selected .pants-trim-color,
.just-jacket-selected .pant-trim-material,
.just-pants-selected .coat-trim-type,
.just-pants-selected .coat-trim-color,
.just-pants-selected .coat-trim-material
{
    display: none !important;
}

.secondary-bar-wrapper .visited {
    color: #808080;
}

.image-swatch-container {
    max-width: 150px;
}

.jacket .left-top-collar,
.jacket .right-top-collar,
.jacket .left-front-holders,
.jacket .right-front-holders,
.jacket .inside-reinforcements,
.jacket .outside-reinforcements,
.jacket .holder---above-trim,
.jacket .closure,
.jacket .holder---stormflap {
    display: block !important;
}

.modal-title, .configurtion-modal-close {
    font-size: 1.125rem;
}
.configuration-list-save,
.configuration-list {
    list-style: none;
    padding: 0;
    font-size: 1.125rem;
    margin-top: 10px;
}
.configuration-list {
    overflow-y: auto;
    max-height: 200px;
}

.configuration-list li {
    text-align: left;    
    border: solid 1px #ebebeb;
    margin: 2px;
    padding: 4px 10px;
    cursor: pointer;
    
}
.configuration-list li:hover {
    background-color: #dadada;
}
.configuration-list li a {
    color: #57585a;
}

.configuration-list li a:hover {
}

.search-configuration {
    margin: 5px;
    margin: 10px 0;
}

.search-configuration input
{
    width: 100%;
    height: 50px;
    padding-left: 10px;
    border: 0.0625rem solid #ebebeb;
    font-size: 1.125rem;
    font-family: 'Open Sans',sans-serif;
    font-weight: 300;
    margin: 0;
}

.modal-message-configuration-list {
    min-height: 300px;
}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    #root .player-container:not(.summary) {
        padding-bottom: 50px;
    }
}


@media (max-width: 991px){
    #root .player-container:not(.summary){
        flex-wrap: wrap;
        justify-content: center;
        
    }
    #root .primary-bar-wrapper .nav--friendly--message{
        font-size: 0.9em;
    }
}
@media screen and (max-width: 736px) {
    /* #root .buttonBar{
        display:none;
    } */
    .three-d-player-space {
        width: 100%;
    }

    .inputs-container .options-item {
        width: max-content;
        padding: 10px 25px;
        border-radius: 10px;
        position: relative;
        margin: 5px;
    }

    #root .jacket .secondary-bar-wrapper {
        height: 80px;
    }

    .container {
        width: 100%;
    }
    #root  .main-menu-wrapper {
        width: auto;
    }

    #root .primary-bar-wrapper .nav--friendly--message{
        max-width: 135px;
    }

    #root .fCIvsh {
        margin:0;
        grid-template-columns: none;
        padding-left: 2rem;
    }

    #root .regular-wapper {
      overflow-y: unset;
      margin-top: 0rem;
    }

    #root .player-container:not(.summary){
        position: unset;
    }

    #player-root{
     
    }

    footer{
        text-align: left;
        width:auto;
        position:fixed;
        bottom:0;
        left:0;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    #root .primary-bar-label{
        font-size: 15px;
    }

    #root .header-logo{
        width:104px;
        height:57px;
        margin-left: 35%;
    }

    #root .primary-bar-wrapper{
        padding:0px !important;
    }

    #root .secondary-bar-wrapper{
        padding-left: 5px;
        font-size: 11px !important;
        width:90vw;
    }
    /* #root .head1{
        margin-top: -3rem !important;
        margin-left:9rem !important;
        font-size: 17px !important;
    }
    #root .head2{
        margin-top: -3rem !important;
        margin-left:9rem !important;
        font-size: 17px !important;
    }

    #root .head3{
        margin-top: -3rem !important;
        margin-left:9rem !important;
        font-size: 17px !important;
    } */

    #root .menu{
        display: block !important;
    }


    #root .homepage{
        min-height: 100% !important;
        height:auto !important;
    }

    #root .summary .summary-wrapper{
        overflow-y: unset;
        width:90vw !important;
        padding-left: 15px;
    }
/*
    #root .zoom-wrapper{
        right: 60px; 
        left: auto;
    }   */
    .image-swatch-container {
        max-width: 95px;
    }
    .image-swatch-container .gear-img {
        min-height: 110px !important;
    }
}
@media screen and (min-width: 720px) and (max-width:991px){
    /* #root .menu{
        margin-top: 5%;
        margin-left:0rem;
    } */

    #root .menuoption{
        margin-bottom: 2%;
     }
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (min-width: 685px) and (max-width:940px){
    
     /* #root .head1{
       margin-left:5rem !important;
    }
    #root .head2{
       margin-left:5rem !important;
    }
    #root .head3{
       margin-left:5rem !important;
    } */
    
}
@media screen and (max-width: 648px) {
    #root .player-container.summary .container {
        width: 100% !important;
        padding-left: 0 !important;
    }
    #root .summary .three-d-player-space {
        width: 100%;
    }
    #root .summary .three-d-player-space {
        display: none;
    }
    #root .summary .nav--subheaders {
        margin-left: 0 !important;
    }

    #root .player-container.summary .summary-wrapper {
        padding-left: 0 !important;
    }
    
    #root .template-wrapper .next-btn{
        margin-top:30px
    }
    /* #root .buttonBar{
        display:none;
    } */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .next-btn {
        bottom: 10px;
    }
}

@media screen and (max-width: 1024px) {
    #root .secondary-bar-wrapper {
        display: flex;
        flex-wrap: wrap;
        height: 79px;
        flex-direction: column;
        width:90vw;
    }
    #root .three-d-player-space {
        margin: 0 auto;
    }
}
@media screen and (max-width: 768px) {
    #root .bHKlIe,
    #root .Omcxy {
        display: block;
        width: 100%;
        padding: 0 40px;
    }
    #player-root {
        width: 100%;
        max-width: none;
        height: 400px;
    }

    #root .primary-bar-wrapper {
        height: auto;
    }
    
    #root .primary-bar-wrapper .primary-bar-item {
        font-size: 14px;
        max-height: 57px;
        padding: 0 8px;
    }
    #root .secondary-bar-wrapper .secondary-bar-item {
        height: 19px;
        padding: 20px 5px;
        border-bottom: solid 2px #eee;
        /* transform: none; */
        width: auto;
    }
    #root .section-wrapper {
        width: 100%;
    }
    .ant-input:focus{
        border-color: #00A94E !important;
      }
    #root .template-wrapper > div {
        margin: auto;
        width: 100%;
    }
    #root .player-container {
        padding: 0 20px;
        width: 100%;
    }
  }

 

  .ant-input{
    text-transform: uppercase;
  }


  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    top: 40%;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  #loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.3;
    z-index: 999;
    text-align: center;
    vertical-align: middle;
    display: none;
  }
  #root .summary .summary--padding{
    padding: 10px 1px;
}
.right-flags .jacket.right.sleeve .custom-patches,
.right-flags .jacket.left.sleeve .flags,
.left-flags .jacket.left.sleeve .custom-patches,
.left-flags .jacket.right.sleeve .flags,
.right-embroidery-patches .jacket.right.sleeve .flags,
.right-embroidery-patches .jacket.left.sleeve .custom-patches,
.left-embroidery-patches .jacket.left.sleeve .flags,
.left-embroidery-patches .jacket.right.sleeve .custom-patches{
    display: none;
}
.none-visible{
    display: none;
}

.configuration-name-group-buttons{
    padding-top: 20px;
}

.input-text-configuration-name{
    padding: 2px 10px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #00A94E;
    text-transform: uppercase;
    margin: 10px;
    font-size: 14px;
}